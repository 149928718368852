footer.main-footer {
  background-color: $MAIN_LIGHT_COLOR;
  border-top: 7px solid $SECONDARY_LIGHT_COLOR;
  margin-top: 20px;
  font-size: remcalc(16px);

  a, p, .footer-kredit-title {
    color: $MAIN_COLOR;
    @include font-DIN-next-regular(remcalc($FOOTER_FONTSIZE));
    line-height: remcalc($FOOTER_LINEHEIGHT);
  }

  a:hover {
    text-decoration: none;
    color: $SECONDARY_LIGHT_COLOR;
  }

  a:focus {
    text-decoration: none;
  }

  .main-footer-wrapper {
    @include container();

    .footer-nav {
      @include make-row();
      padding: 50px 0;
      list-style: none;

      margin-bottom: 0;

      > li {
        @include make-sm-column(12);
        @include make-md-column(3);
        line-height: remcalc(36px);

        > a, .footer-kredit-title {
          margin-bottom: 18px;
          display: block;
          @include font-DIN-next-medium(remcalc(18px));
          @include breakpoint(xs-only) {
            font-size: 16px;
          }

          &:after {
            @extend .dolle-icon;
          }
        }

        .footer-kredit-title {
          text-align: center;
        }

        .footer-kredit-img {
          display: block;
          height: 95px;
          margin-left: auto;
          margin-right: auto;
        }

        > ul {
          padding: 0;

          > li {
            list-style: none;

            &:before {
              @extend .dolle-icon;
              content: $icon-arrow_right_mini;
              display: inline-block;
              width: 14px;
              font-size: 10px;
              line-height: 16px;
              color: $FOOTER_BORDER_COLOR;
            }

            a {
              @include breakpoint(xs-only) {
                font-size: 16px;
              }
            }

            img {
              display: block;
              float: none;
              margin-bottom: 18px;
            }


            &.has-form {
              > a {
                border-bottom: 0;
              }
            }
          }
        }
      }

      [class^="footer-contact-"] {
        color: $MAIN_COLOR;

        address {
          a {
            line-height: inherit;
          }
        }
      }

      &:first-child {
        > li:first-child {
          @include make-md-column(6);

          > ul {
            @include breakpoint(md) {
              columns: 2;
            }
          }
        }
      }


      .newsletter-form {
        margin-bottom: 18px;
        margin-top: 30px;

        &:before {
          display: none;
        }


        [type=email] {
          margin-right: 0;
          width: calc(100% - 48px);
          border: none;
          height: 40px;
          line-height: 40px;

          @include breakpoint(md-only) {
            width: 160px;
          }

          @include breakpoint(xs-only) {
            height: 30px;
            line-height: 30px;
          }
        }


        .btn-primary {
          width: 30px;
          min-width: 30px;
          max-width: 30px;
          height: 30px;

          padding: 0;
          border: none;

          line-height: 35px;
          text-align: center;

          @include breakpoint(sm) {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;

            line-height: 45px;
          }
        }

      }


      .map-form {
        padding-bottom: remcalc(16px);


        > p {
          padding-left: 0;

          @include copytext-config();
          font-size: remcalc(14px) !important;
          line-height: 1.25;

          @include breakpoint(sm) {
            padding-left: 14px;
          }
        }


        > form {
          display: block;
          @include clearfix();

          padding-left: 0;

          @include breakpoint(sm) {
            padding-left: 14px;
          }


          > * {
            float: left;
          }


          > [type=text] {
            margin-right: 0;
            width: calc(100% - 48px);
            border: none;
            height: 40px;
            line-height: 40px;

            @include breakpoint(md-only) {
              width: 155px;
            }

            @include breakpoint(xs-only) {
              height: 30px;
              line-height: 30px;
            }
          }


          > .btn-primary {
            width: 30px;
            min-width: 30px;
            max-width: 30px;
            height: 30px;

            padding: 0;
            border: none;

            line-height: 35px;

            @include breakpoint(sm) {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;

              line-height: 45px;
            }


            &.subscribe {
              padding: 2px 6px 3px !important;
            }
          }
        }
      }


      .social-nav {
        li {
          float: left;
          line-height: 40px;

          &:before {
            display: none;
          }

          > a {
            height: 40px;
            width: 40px;
            display: inline-block;
            margin-right: 20px;

            &.social-icon-fb {
              @include image-replacement('../img/icon_fb.png');
            }

            &.social-icon-yt {
              @include image-replacement('../img/icon_youtube.svg');
              background-size: 50px 50px;
            }

            &.social-icon-tw {
              @include image-replacement('../img/icon_tw.png');
            }

            &.social-icon-xing {
              @include image-replacement('../img/icon_xing.svg');
              background-size: 50px 50px;
            }

            &.social-icon-gplus {
              @include image-replacement('../img/icon_googleplus.svg');
              background-size: 50px 50px;
            }

            &.social-icon-linkedin {
              @include image-replacement('../img/icon_linkedin.svg');
              background-size: 100% auto;
            }

            &.social-icon-facebook {
              @include image-replacement('../img/icon_facebook.svg');
              background-size: 100% auto;
            }

            &.social-icon-instagram {
              @include image-replacement('../img/icon_instagram.svg');
              background-size: 100% auto;
            }

            &.social-icon-pinterest {
              @include image-replacement('../img/icon_pinterest.svg');
              background-size: 100% auto;
            }

            &.dk-social-icon-yt {
              @include image-replacement('../img/dk-youtube-icon.svg');
            }

            &.dk-social-icon-facebook {
              @include image-replacement('../img/dk-facebook-icon.svg');
            }

            &.dk-social-icon-instagram {
              @include image-replacement('../img/dk-instagram-icon.svg');
            }

            &.dk-social-icon-linkedin {
              @include image-replacement('../img/dk-linkedin-icon.svg');
            }

            &.dk-social-icon-houzz {
              @include image-replacement('../img/dk-houzz-icon.svg');
            }

            &.dk-social-icon-pinterest {
              @include image-replacement('../img/dk-pinterest-icon.svg');
            }

            &.dk-social-icon-twitter {
              @include image-replacement('../img/dk-twitter-icon.svg');
            }

            &[class^="dk-social-icon-"] {
              background-size: 100% auto;
            }
          }

          font: initial;
          margin-top: -8px;

          &:last-child {
            > a {
              margin-right: 0;
            }
          }
        }
      }

      .app-nav {
        margin-top: 25px;

        .app-de-apple-link {
          @media (max-width: $screen-md-max) {
            margin-top: 0;
          }
        }

        .app-de-android-link, .app-de-apple-link {
          a {
            width: auto;
            height: auto;
          }

          img {
            margin-bottom: 0;
          }
        }

        .app-de-android-link {
          margin-top: 10px;
        }
      }
    }


    hr {
      border-color: rgba(92, 88, 81, 0.3);
      margin: 0;
    }
  }

  .meta-footer {
    @include container();

    .meta-footer-wrapper {
      @include make-row();
      padding: 20px 0;

      .meta-footer-nav,
      .meta-footer-content {
        @include make-md-column(6);
      }

      .meta-footer-content {
        img {
          float: left;
          margin-right: 10px;
        }

        p {
          line-height: 44px;
        }
      }

      .meta-footer-nav {
        .footer-nav {
          padding: 0;
          display: flex;
          justify-content: space-between;

          li {
            line-height: 44px;
            list-style: none;

            &:before {
              @extend .dolle-icon;
              content: $icon-arrow_right_mini;
              display: inline-block;
              width: 14px;
              font-size: 10px;
              line-height: 16px;
              color: $FOOTER_BORDER_COLOR;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  /// MOBILE FOOTER
  @media (max-width: $screen-sm-max) {
    .main-footer-wrapper {
      margin-top: 30px;

      hr {
        display: none;
      }

      .footer-nav {


        padding: 0;
        border: 0;

        &:first-child {
          > li {
            &:first-child {
              > a, .footer-kredit-title {
                border-top: 0;
              }
            }
          }
        }

        > li {
          > a, .footer-kredit-title {
            position: relative;
            text-transform: uppercase;
            display: block;
            border-top: 1px solid $FOOTER_BORDER_COLOR;
            border-bottom: 0;
            height: 34px;
            line-height: 38px;
            margin: 0;

            &:after {
              content: $icon-arrow_down;
              position: absolute;
              right: 0;
              font-size: 12px;
              line-height: 40px;
              color: $SECONDARY_LIGHT_COLOR;
            }

            &.collapsed {
              &:after {
                content: $icon-arrow_up;
              }

              + ul {
                //display: block;
              }

              border-bottom: 1px solid $FOOTER_BORDER_COLOR;
            }
          }

          .footer-kredit-title {
            text-align: left;

            &.collapsed {
              color: $SECONDARY_LIGHT_COLOR;
            }
          }

          .footer-kredit-img {
            margin: 10px auto;
          }

          > ul {
            display: none;

            > li {
              margin-left: 20px;

              &:before {
                display: none;
              }

              > a, .footer-kredit-title {
                display: block;
                min-height: 34px;
                line-height: 34px;
                border-bottom: 1px solid $FOOTER_BORDER_COLOR;
              }

              &:last-child {
                > a .footer-kredit-title {
                  border-bottom: 0;
                }
              }
            }
          }
        }

        .social-nav {
          margin: 0;

          li {
            margin-bottom: 12px;
          }
        }

        .newsletter-form {
          margin-top: 15px;
          margin-bottom: 0;

          > form {
            padding-bottom: 15px;
            border-bottom: 1px solid $FOOTER_BORDER_COLOR;
          }

          .btn-primary {
            margin-left: 1px;
          }
        }
      }

      .newsletter-form {
        input[type="email"] {
          width: calc(100% - 130px);
        }
      }
    }
    .meta-footer {
      .meta-footer-wrapper {
        padding-top: 0;

        .meta-footer-content {
          padding-top: 20px;
        }

        .meta-footer-nav {
          .footer-nav {
            display: block;

            > li {
              margin-left: 0;

              &:before {
                display: none;
              }

              > a {
                display: block;
                border-bottom: 1px solid $FOOTER_BORDER_COLOR;
              }

              &:last-child {
                > a {
                  border: 0;
                }
              }
            }
          }
        }
      }
    }
  }

}

.cookie-layer {
  display: none;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;

  background-color: $MAIN_LIGHT_COLOR;

  box-shadow: 5px 8.66px 30px 0 rgba(0, 0, 0, 0.2);

  .cookie-layer-wrapper {

    @include container();

    margin-top: 20px;
    margin-bottom: 20px;

    .cookie-layer-holder {

      @include breakpoint(sm) {
        display: flex;
      }

      @include make-row();

      .cookie-layer-content-holder {
        @include make-sm-column(9);
        @include font-DIN-next-light(remcalc(18px), remcalc(24px));

        color: #918c82;
      }

      .cookie-layer-button-holder {
        @include make-sm-column(3);

        align-self: center;
        text-align: right;

        .close-cookie-layer {
          @extend .btn;
          @extend .btn-primary;
          height: auto;
        }
      }
    }
  }
}

#dolle-dk, #dolle-se, #dolle-pl, #dolle-uk, #dolle-no {
  footer.main-footer {
    .main-footer-wrapper {
      .footer-nav:last-of-type {
        > li:nth-child(2) {
          @include make-md-column(6);

          > ul {
            @include breakpoint(md) {
              columns: 2;
            }
          }
        }
      }
    }
  }
}

body#dolle-eu, body#dolle-com, body#dolle-lt  {
  footer.main-footer {
    .main-footer-wrapper {
      .footer-nav:last-of-type {
        > li:nth-child(1) {
          @include make-md-column(6);

          > ul {
            @include breakpoint(md) {
              columns: 2;
            }
          }
        }
      }

      .footer-nav:first-of-type {
        > li:nth-child(1) {
          @include make-md-column(3);

          > ul {
            @include breakpoint(md) {
              columns: 1;
            }
          }
        }
      }

      .footer-kredit {
        @include breakpoint(lg) {
          float: right;
          .footer-kredit-title {
            text-align: left;
          }
          .footer-kredit-img {
            margin: 0;
          }
        }

      }

    }
  }
}

body#sogem-eu, body#dolle-fi {
  footer.main-footer {
    .main-footer-wrapper {
      .footer-nav {
        > li:nth-child(1) {
          @include make-md-column(6);

          > ul {
            @include breakpoint(md) {
              columns: 2;
            }
          }
        }
      }
    }
  }
}

