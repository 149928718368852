/* Slider */

.slick-slider {
	position: relative;
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	outline: none;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-prev, .slick-next {
	display: block;
	cursor: pointer;
	outline: none;

	position: absolute;
	top: calc(50% - 15px);
	z-index: 99;

	width: 40px;
	height: 30px;

	padding: 0;
	border: none;
	margin-top: -10px;

	font-size: 0;
	line-height: 0;
	color: transparent;

	background: transparent;

	&:before {
		display: block;
		@extend .dolle-icon;
		font-size: 24px;
		color: #FFF;
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.66);
	}
	&:hover {
		background: transparent;
		&:before {
			color: $SECONDARY_LIGHT_COLOR;
		}
	}
}

.slick-next {
	right: 12px;
	&:before {
		content: $icon-arrow_right;
	}
}

.slick-prev {
	left: 14px;
	&:before {
		content: $icon-arrow_left;
	}
}

.slick-dots {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
	width: 100%;
	padding: 0;
	padding-bottom: 10px;
	list-style: none;
	text-align: center;
	li {
		position: relative;
		display: inline-block;
		width: 12px;
		height: 12px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			font-size: 0;
			line-height: 0;
			display: block;
			min-width: 0;
			width: 12px;
			height: 12px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: $MAIN_LIGHT_COLOR;
			border-radius: 50%;
		}
		&.slick-active {
			button {
				background-color: $SECONDARY_LIGHT_COLOR;
			}
		}
	}
}
.slick-prev:active, .slick-next:active,
.slick-prev:hover, .slick-next:hover {
	border: 0;
	background-color: transparent;
	box-shadow: none;
}
