// Almost the same as compass replace-text
// but adding direction: ltr
@mixin image-replacement($img: none, $x: 50%, $y: 50%) {
  @include hide-text;
  background-repeat: no-repeat;
  direction: ltr;
  @if $img != none {
    background-image: url($img);
    background-position: $x $y;
  }
}

.ce-textpic.module.module-text.ce-center.ce-below{
  .ce-gallery{
    display: contents;
  }
}