body > .ui-autocomplete {
	list-style: none;
	padding: 2px;
	margin: 0;
	display: block;
	float: left;
	background: $BODY_BG_COLOR;
	color: $INPUT_TEXT_COLOR;
	border: 1px solid #dddddd;

	.ui-menu-item {
		[id^='ui-id-'] {
			@include copytext-config();

			display: block;
			border: 0;
			margin: 0;
			color: $INPUT_TEXT_COLOR;
			padding: .2em .4em;
			line-height:1.5;
			zoom: 1;

			strong {
				@include font-DIN-next-bold(inherit, inherit);
			}

			&:hover{
				text-decoration: none;
			}

			&.ui-state-active,
			&.ui-state-focus {
				border: 0;
				margin: 0;

				color: $SECONDARY_LIGHT_COLOR;

				background: $MAIN_LIGHT_COLOR;
			}
		}
	}
}

body > .ui-helper-hidden-accessible {
	display: none !important;
}



#header-search {
	.ui-helper-hidden-accessible {
		display: none !important;
	}

	.tx-solr-q.ui-autocomplete-input {
		@include font-DIN-next-regular(remcalc(16px));
		color: $INPUT_TEXT_COLOR;
		line-height: $BUTTON_LINE_HEIGHT_SM_UP;
		padding: 0 10px;
	}
}
