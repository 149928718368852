main.content {
    .tx-solr {
        @include make-row();
        padding-bottom: 50px;

        #tx-solr-search-form-pi-results {
            .tx-solr-q {
                width: 75%;
                padding-left: 10px;
                margin-right: 0;

                @include breakpoint(md) {
                    width: 50%;
                }
            }

            .tx-solr-submit {
                width: 40px;
            }
        }

        .tx-solr-submit {
            background-color: $SECONDARY_LIGHT_COLOR;
            color: $BUTTON_TEXT_COLOR;
        }

        #tx-solr-search {
            @include make-xs-column(12);
            margin-top: 75px;

            .tx-solr-search-form {
                background-color: $BOX_BG_COLOR_LIGHT;
                padding: 30px;
                margin-bottom: 40px;
                border: none;
            }

            #tx-solr-search-functions {
                width: 100%;
                margin-bottom: 40px;
                @include font-DIN-next-regular();

                #tx-solr-faceting {
                    #tx-solr-facets-available,
                    #tx-solr-facets-in-use {
                        margin-bottom: 0;
                        padding-bottom: 0;

                        .csc-header,
                        .facets {
                            padding: 5px 0;
                        }

                        .facets {
                            h5 {
                                padding: 5px;
                            }
                        }

                        .csc-header {
                            h3 {
                                text-transform: uppercase;
                                @include font-DIN-next-regular(remcalc(24px));
                            }
                        }

                        ul {
                            padding: 0;

                            li {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .tx-solr-search-result-summary {
                margin-bottom: 40px;
            }

            ol {
                padding-left: 0;
            }

            .pagination {
                > li {
                    display: inline-block;

                    &.page-prev, &.page-next {
                        a {
                            border: 0 none;
                            background-color: $brand-primary;
                            color: #fff;

                            &:hover {
                                background-color: darken($brand-primary, 10%);
                            }
                        }
                    }

                    &:not(.page-prev):not(.page-next ) {
                        > a,
                        > span {
                            color: $MAIN_COLOR;
                            border: 0 none;
                            padding: $padding-base-vertical 18px;

                            &:hover {
                                color: $brand-primary;
                            }
                        }
                    }
                }

                > .active > a,
                > .active > span {
                    &,
                    &:hover,
                    &:focus {
                        color: $brand-primary;
                        background-color: transparent;
                    }
                }
            }

            #tx-solr-pagination {
                height: auto;

                margin: 40px 0;
                padding: 0 0 21px;
                border-bottom: 1px solid $MAIN_LIGHT_COLOR;

                overflow: hidden;

                #results-per-page {
                    float: none;
                    display: inline-block;

                    @include breakpoint(xs-only) {
                        display: none;
                    }

                    form {
                        width: 190px;
                    }

                    .selectproxy {
                        width: auto;
                        display: inline-block;
                        min-width: 90px;
                    }

                    .limiter-text {
                        line-height: 38px;
                    }
                }

                .tx-solr-pagination-page-browser {
                    @include breakpoint(xs-only) {
                        float: none;
                    }

                    &:before {
                        content: '';
                        display: table;
                    }

                    &:after {
                        clear: both;
                    }

                    .tx-pagebrowse {
                        display: inline-block;
                        padding: 0;
                        //height: 40px;
                        margin: 0;

                        > li {
                            display: inline-block;
                            padding-right: 0;
                        }

                        .tx-pagebrowse-pages {
                            .tx-pagebrowse-page {
                                line-height: 32px;

                                a {
                                    padding: 0 4px;
                                    color: $MAIN_COLOR;

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }

                            .tx-pagebrowse-page,
                            .tx-pagebrowse-current {
                                margin: 1px 20px 0 0;
                                font-size: 1rem;
                                padding: 2px 0 0 0;

                                @include breakpoint(xs-only) {
                                    margin: 1px 15px 0 0;
                                }
                            }

                            .tx-pagebrowse-current {
                                span {
                                    padding: 0 4px;
                                    font-weight: bold;
                                    color: $SECONDARY_LIGHT_COLOR;
                                }
                            }
                        }

                        .tx-pagebrowse-prev,
                        .tx-pagebrowse-next {
                            a {
                                height: 38px;
                                width: 38px;
                                line-height: 38px;
                                padding: 2px 0 0 0;
                            }
                        }

                        .tx-pagebrowse-prev {
                            margin-right: 30px;

                            @include breakpoint(xs-only) {
                                margin-right: 15px;
                            }
                        }

                        .tx-pagebrowse-next {
                            margin-left: 10px;
                            padding-right: 0;

                            @include breakpoint(xs-only) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            .results-list {
                border-top: 1px solid #cfcecf;
                margin-top: 0;
                display: inline-block;
                padding-top: 10px;
                width: 100%;

                .red {
                    color: $SECONDARY_LIGHT_COLOR;
                }

                .results-teaser {
                    color: $MAIN_COLOR;
                    margin: 30px 0;
                    display: block;

                    .result-content-wrapper {
                        flex-grow: 1;
                    }

                    .result-content {
                        @include font-DIN-next-light(remcalc(18px), remcalc(24px));

                        .results-highlight {
                            @include font-DIN-next-bold(remcalc(18px), remcalc(24px));
                            background: none;
                        }
                    }

                    &.has-image, .results-headline {
                        display: flex;
                    }

                    &.has-image {
                        align-content: flex-start;
                        align-items: flex-start;

                        .result-image {
                            img {
                                max-width: 120px;
                                margin-right: 20px;
                            }
                        }

                        .results-topic {
                            margin-top: 0;
                        }

                        .result-content {
                            margin: 0;
                        }
                    }

                    .results-headline {
                        justify-content: space-between;
                        margin: 5px 0 8px;

                        .sub-title {
                            flex-grow: 1;
                        }

                        .price {
                            color: $SECONDARY_LIGHT_COLOR;
                            @include font-DIN-next-bold(remcalc(21px));
                        }
                    }

                    &:hover {
                        text-decoration: none;

                        .results-topic {
                            text-decoration: underline;
                        }
                    }
                }

                @media screen and (max-width: $screen-xs-max) {
                    .results-teaser {
                        .results-headline {
                            flex-direction: column;

                            .sub-title {
                                margin: 5px 0;
                            }

                            .price {
                                @include font-DIN-next-bold(remcalc(18px));
                            }
                        }

                        &.has-image {
                            .result-image {
                                img {
                                    margin-right: 15px;
                                    max-width: 100px;
                                }
                            }
                        }
                    }
                }
                @media screen and (min-width: $screen-xs-min) {
                    .results-teaser {
                        .results-headline {
                            align-items: center;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                        }

                        .result-content {
                            max-width: 80%;
                        }
                    }
                }
            }
        }
    }
}

.tx-solr-autosuggest {
    border: 1px solid $dropdown-fallback-border;
    overflow: auto;

    .autocomplete-suggestion {
        padding: 2px 5px;
        white-space: nowrap;
        overflow: hidden;
    }

    .autocomplete-group {
        padding: 10px 5px;
    }
}

.autocomplete-suggestions, .autocomplete-suggestions.tx-solr-autosuggest {
    background: $BODY_BG_COLOR;

    .autocomplete-suggestion {
        @include copytext-config();
        color: $INPUT_TEXT_COLOR;
        border: 0;
        margin: 0;

        strong {
            @include font-DIN-next-bold(inherit, inherit);
            color: $INPUT_TEXT_COLOR;
        }

        &.autocomplete-selected {
            border: 0;
            margin: 0;
            color: $SECONDARY_LIGHT_COLOR;
            background: $MAIN_LIGHT_COLOR;
        }
    }
}
