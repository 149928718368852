=breakpoint($point)
	@if $point == xl
		@media (min-width: $screen-xl-min)
			@content
	@else if $point == lg
		@media (min-width: $screen-lg-min)
			@content
	@else if $point == md-only
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max)
			@content
	@else if $point == md
		@media (min-width: $screen-md-min)
			@content
	@else if $point == sm-only
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max)
			@content
	@else if $point == sm
		@media (min-width: $screen-sm-min)
			@content
	@else if $point == xs-only
		@media (max-width: $screen-xs-max)
			@content
	@else if $point == xs
		@content
	@else if $point == xxs-only
		@media (max-width: $screen-xs)
			@content
