@function remcalc($pxValue) {
    @if unitless($pxValue) {
        $pxValue: $pxValue + 0px;
    }
    @return calc($pxValue / $FONTSIZE-BASE-SM-UP) + 0rem;
}



@function gutterval($breakpoint: "xs", $factor: 1) {
  /*@if $breakpoint == "xs" {
    @return $grid-gutter-width-xs * $factor;
  }
  @else if $breakpoint == "sm" {
    @return $grid-gutter-width-sm * $factor;
  }
  @else if $breakpoint == "md" {
    @return $grid-gutter-width-md * $factor;
  }
  @else if $breakpoint == "lg" {
    @return $grid-gutter-width-lg * $factor;
  }
  @else if $breakpoint == "xl" {
    @return $grid-gutter-width-lg * $factor;
  }*/

  @return $grid-gutter-width * $factor;
}



@mixin gutterbased($attribute, $factor: 1, $add: 0) {
  /*@media (max-width: $screen-xs-max) {
    #{$attribute}: gutterval(xs, $factor) + $add;
  }
  @media (min-width: $screen-sm-min) {
    #{$attribute}: gutterval(sm, $factor) + $add;
  }
  @media (min-width: $screen-md-min) {
    #{$attribute}: gutterval(md, $factor) + $add;
  }
  @media (min-width: $screen-lg-min) {
    #{$attribute}: gutterval(lg, $factor) + $add;
  }
  @media (min-width: $screen-xl-min) {
    #{$attribute}: gutterval(xl, $factor) + $add;
  }*/

  #{$attribute}: gutterval(xs, $factor) + $add;
}



@mixin css-linebreak($after: true, $before: false) {
  @if $before {
    &::before {
      content: "\a ";
      white-space: pre;
    }
  }
  @if $after {
    &::after {
      content: "\a ";
      white-space: pre;
    }
  }
}



@mixin touch-hover-fix {
  body:not(.touch) &:hover,
  body.touch &:focus {
    @content;
  }
}



@mixin container {
  @extend .container;
}



@mixin highdpi-background-image($url, $width: null, $height: null, $smartAdapt: false, $important: false) {
  $minWidth: 0;
  @if $smartAdapt {
    $minWidth: $screen-xl-min / 2 + 1;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: $minWidth), only screen and (-o-min-device-pixel-ratio: 3 / 2) and (min-width: $minWidth), only screen and (min--moz-device-pixel-ratio: 1.5) and (min-width: $minWidth), only screen and (min-device-pixel-ratio: 1.5) and (min-width: $minWidth), only screen and (min-resolution: 144dpi) and (min-width: $minWidth), only screen and (min-resolution: 1.5dppx) and (min-width: $minWidth) {
    & {
      @if $important {
        background-image: url($url) !important;
      }
      @else {
        background-image: url($url);
      }
      @if $width != null and $height != null {
        @if $important {
          -webkit-background-size: $width $height !important;
          -moz-background-size: $width $height !important;
          -o-background-size: $width $height !important;
          background-size: $width $height !important;
        }
        @else {
          -webkit-background-size: $width $height;
          -moz-background-size: $width $height;
          -o-background-size: $width $height;
          background-size: $width $height;
        }
      }
    }
  }
}



@mixin input-placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin headings {
  h1, h2, h3,
  h4, h5, h6 {
    @content;
  }
}

@mixin text-tags {
  p, strong, span, a, table, ul, ol, blockquote, cite, address, h1, h2, h3,
  h4, h5, h6 {
    @content;
  }
}
