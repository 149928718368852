@font-face {
    font-family: 'dolle-icons';
    src: url('#{$FONT_PATH}dolle-icons.woff?kkodt1') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Light";
    src: url("#{$FONT_PATH}din_next/89088fc9-98f7-4390-9084-89e6dba8bc08.woff2") format("woff2"), url("#{$FONT_PATH}din_next/e81c5806-8af1-40c4-bf7b-f548375ddcef.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W01 Light Italic";
    src: url("#{$FONT_PATH}din_next/432c9e2a-ea2e-479f-8920-a944904adff2.woff2") format("woff2"), url("#{$FONT_PATH}din_next/a062545d-8a1d-4a8c-88ce-48870c7cb3ca.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Light Italic";
    src: url("#{$FONT_PATH}din_next/fdd172da-2c66-47f9-964e-654f6ee9cd6d.woff2") format("woff2"), url("#{$FONT_PATH}din_next/ffcd5d83-aa0f-451c-b418-ac360ab874d0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Regular";
    src: url("#{$FONT_PATH}din_next/86213c25-c304-41be-8e81-eb0091ce7add.woff2") format("woff2"), url("#{$FONT_PATH}din_next/7353a35c-537a-46d5-b63a-d82b4353563a.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W01 Italic";
    src: url("#{$FONT_PATH}din_next/e8258209-d292-4480-9fe3-b30dfa63ea97.woff2") format("woff2"), url("#{$FONT_PATH}din_next/c3cbbafb-1878-424d-b2f5-b176d0f9b370.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Italic";
    src: url("#{$FONT_PATH}din_next/8ec71492-2e02-4717-b228-ec037c155575.woff2") format("woff2"), url("#{$FONT_PATH}din_next/7da85c1c-977c-4478-a835-324c3a71bf91.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Medium";
    src: url("#{$FONT_PATH}din_next/28870ae1-8b80-4aa8-8540-5071496358ce.woff2") format("woff2"), url("#{$FONT_PATH}din_next/0c5d4204-7e57-450c-84cf-f40ebb8fe6b7.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W01 Medium Italic";
    src: url("#{$FONT_PATH}din_next/e7938824-19f4-4f10-a2f1-a0bf5f7ab502.woff2") format("woff2"), url("#{$FONT_PATH}din_next/34afc1f7-a48d-4091-83dc-cd888e3d0df8.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Medium Italic";
    src: url("#{$FONT_PATH}din_next/7950a832-d9d3-4d01-b9a4-7a07cfe5738d.woff2") format("woff2"), url("#{$FONT_PATH}din_next/bbd0bc87-8146-45a9-a932-666e30b273f9.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W01 Bold";
    src: url("#{$FONT_PATH}din_next/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"), url("#{$FONT_PATH}din_next/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Bold";
    src: url("#{$FONT_PATH}din_next/1410c861-6f68-441e-b3b7-47d32c46fe78.woff2") format("woff2"), url("#{$FONT_PATH}din_next/0688a3c1-4ddd-403c-a671-629da0e16085.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W01 Bold Italic";
    src: url("#{$FONT_PATH}din_next/a6eb0ff6-9fe2-47b1-947e-fe6d477fea22.woff2") format("woff2"), url("#{$FONT_PATH}din_next/194d1c66-dc36-4c21-90af-3fe62de2fc02.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Bold Italic";
    src: url("#{$FONT_PATH}din_next/ad4a4f26-bdc9-40ac-904e-ce72523fc64b.woff2") format("woff2"), url("#{$FONT_PATH}din_next/b23d189b-2280-4424-aec7-ace146a74144.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Heavy";
    src: url("#{$FONT_PATH}din_next/b8a8a0dc-d9a2-49bd-8eaf-5f8245da5c74.woff2") format("woff2"), url("#{$FONT_PATH}din_next/5783568c-4ab9-46f7-82c7-624f9e11dc28.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Heavy Italic";
    src: url("#{$FONT_PATH}din_next/550ee402-7011-43bd-a329-3fcf6d7f7967.woff2") format("woff2"), url("#{$FONT_PATH}din_next/8af35ae5-54de-4b43-bc42-aeac6f9e9569.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Black";
    src: url("#{$FONT_PATH}din_next/aea03b2e-6d91-4cb7-a412-7622db802c3b.woff2") format("woff2"), url("#{$FONT_PATH}din_next/035a394b-d595-446c-a894-13863a670b1b.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next LT W02 Black Italic";
    src: url("#{$FONT_PATH}din_next/85b3639b-8268-4b5b-b7c3-134e92b15576.woff2") format("woff2"), url("#{$FONT_PATH}din_next/a302b787-d7d2-4ceb-8254-55e91e600ffa.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Light";
    src: url("#{$FONT_PATH}din_next/52bb0d5e-5027-4bb3-b664-69e11f584111.woff2") format("woff2"), url("#{$FONT_PATH}din_next/5ff8e326-572c-4b02-95fe-b1780934b237.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Light Italic";
    src: url("#{$FONT_PATH}din_next/329416f4-48d3-4871-9635-7cc0b24860ee.woff2") format("woff2"), url("#{$FONT_PATH}din_next/8474ef3d-7287-4cc4-9999-a136b561183c.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Regular";
    src: url("#{$FONT_PATH}din_next/f31a3576-267d-42b3-911c-8e027f8c4b59.woff2") format("woff2"), url("#{$FONT_PATH}din_next/a42599aa-1168-4f3c-9ef8-b9cbfdd83afb.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Italic";
    src: url("#{$FONT_PATH}din_next/11ec7aa3-8491-484a-9980-176dc5fd3896.woff2") format("woff2"), url("#{$FONT_PATH}din_next/188ed584-ebb1-4b57-9ee8-815f44024b28.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Medium";
    src: url("#{$FONT_PATH}din_next/5c54e4c9-db37-480a-b0fd-5fa49e9a475c.woff2") format("woff2"), url("#{$FONT_PATH}din_next/bd93da5c-0c79-497f-9e5b-5581aecdde55.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Medium Italic";
    src: url("#{$FONT_PATH}din_next/330958a4-c2c3-4d58-b6fd-50cee8b93fe3.woff2") format("woff2"), url("#{$FONT_PATH}din_next/7451940f-91e1-4f0c-88c9-e752803a23a7.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Bold";
    src: url("#{$FONT_PATH}din_next/86f65a8f-9f2b-444f-aab5-696248713b9e.woff2") format("woff2"), url("#{$FONT_PATH}din_next/d9f06827-3925-4c94-b1a8-17542dd78139.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Bold Italic";
    src: url("#{$FONT_PATH}din_next/b01803f7-8c7b-4900-9d2c-acb1514c5cb5.woff2") format("woff2"), url("#{$FONT_PATH}din_next/632fed5e-d442-4c0e-9574-9a4876b8e68e.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Heavy";
    src: url("#{$FONT_PATH}din_next/9c1d404a-8dce-49f5-91fd-dfe1353dafb2.woff2") format("woff2"), url("#{$FONT_PATH}din_next/fbc23583-1e9e-42f8-97db-470cabbeab84.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Heavy Italic";
    src: url("#{$FONT_PATH}din_next/29d79cd1-a7f5-475b-a692-d301899d4d73.woff2") format("woff2"), url("#{$FONT_PATH}din_next/95b93922-eba4-49c3-bd28-09eb5add97ba.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Black";
    src: url("#{$FONT_PATH}din_next/37f68369-10e5-4d62-a31d-aacec245437c.woff2") format("woff2"), url("#{$FONT_PATH}din_next/58911878-368e-498c-bf43-455ee0cda081.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W02 Black Italic";
    src: url("#{$FONT_PATH}din_next/9928031b-56c5-4fc1-bd4c-5b7153e3599a.woff2") format("woff2"), url("#{$FONT_PATH}din_next/dd6381ca-ab02-4f56-ae6c-7789988b0c8d.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W01 Light";
    src: url("#{$FONT_PATH}din_next/de1216be-00c2-43c0-9c45-3e7f925eb519.woff2") format("woff2"), url("#{$FONT_PATH}din_next/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DINNextW01-LightItalic";
    src: url("#{$FONT_PATH}din_next/26f280d7-8bd0-4192-90f1-3c3cb4cde341.woff2") format("woff2"), url("#{$FONT_PATH}din_next/e1c997f3-fb27-4289-abc6-5ef059e51723.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W01 Regular";
    src: url("#{$FONT_PATH}din_next/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"), url("#{$FONT_PATH}din_next/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W01 Italic";
    src: url("#{$FONT_PATH}din_next/f37f8098-b16e-405f-ab24-bd595a5d5762.woff2") format("woff2"), url("#{$FONT_PATH}din_next/c5635d60-91fa-4e4b-8a51-41c97fc744c3.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W01 Medium";
    src: url("#{$FONT_PATH}din_next/be607c9f-3c81-41b4-b7ad-17af005c80bb.woff2") format("woff2"), url("#{$FONT_PATH}din_next/4bf0ead4-e61b-4992-832b-6ff05828d99f.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DINNextW01-MediumItalic";
    src: url("#{$FONT_PATH}din_next/5e7b11e8-112d-42fe-a35c-edaca8e64677.woff2") format("woff2"), url("#{$FONT_PATH}din_next/00ebffbe-b1fe-4913-b8d8-50f0ba8af480.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DIN Next W01 Bold";
    src: url("#{$FONT_PATH}din_next/121784c1-863c-4e49-b682-625ea7de55b0.woff2") format("woff2"), url("#{$FONT_PATH}din_next/9949035f-6226-4080-a14a-bf7d94ffb1e1.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "DINNextW01-BoldItalic";
    src: url("#{$FONT_PATH}din_next/4e20348d-41a7-4295-94ab-2b1019e9d453.woff2") format("woff2"), url("#{$FONT_PATH}din_next/140a0a6f-f218-4071-8eb8-f2bc45ed5121.woff") format("woff");
    font-display: swap;
}


[class^="icon-"], [class*=" icon-"], .dolle-icon, .downloads-item > .item-icon:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'dolle-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-sorting_asc .path1 {
    &:before {
        content: $icon-sorting_asc-path1;
        color: rgb(209, 0, 25);
    }
}

.icon-sorting_asc .path2 {
    &:before {
        content: $icon-sorting_asc-path2;
        margin-left: -0.6455078125em;
        color: rgb(210, 210, 210);
    }
}

.icon-sorting_desc .path1 {
    &:before {
        content: $icon-sorting_desc-path1;
        color: rgb(209, 0, 25);
    }
}

.icon-sorting_desc .path2 {
    &:before {
        content: $icon-sorting_desc-path2;
        margin-left: -0.6455078125em;
        color: rgb(210, 210, 210);
    }
}

.icon-arrow_down_mini {
    &:before {
        content: $icon-arrow_down_mini;
    }
}

.icon-arrow_down {
    &:before {
        content: $icon-arrow_down;
    }
}

.icon-arrow_left {
    &:before {
        content: $icon-arrow_left;
    }
}

.icon-arrow_right_mini {
    &:before {
        content: $icon-arrow_right_mini;
    }
}

.icon-arrow_right {
    &:before {
        content: $icon-arrow_right;
    }
}

.icon-arrow_up {
    &:before {
        content: $icon-arrow_up;
    }
}

.icon-check {
    &:before {
        content: $icon-check;
    }
}

.icon-geo_pin {
    &:before {
        content: $icon-geo_pin;
    }
}

.icon-search {
    &:before {
        content: $icon-lupe_desktop;
    }
}

.icon-search_retina {
    &:before {
        content: $icon-lupe_retina;
    }
}

.icon-pdf_download {
    &:before {
        content: $icon-pdf_download;
    }
}

.icon-plus_icon {
    &:before {
        content: $icon-plus_icon;
    }
}

.icon-wkicon_retina {
    &:before {
        content: $icon-wkicon_retina;
    }
}

.icon-x_icon {
    &:before {
        content: $icon-x_icon;
    }
}

.icon-cart {
    &:before {
        content: '' !important;
        background-image: url('#{$IMG_PATH}wkicon_desktop.png');
        background-position: center center;
        width: 25px;
        height: 25px;
        display: inline-block;
        background-repeat: no-repeat;

        @include highdpi-background-image('#{$IMG_PATH}wkicon_retina.png', 23px, 22px);
    }
}

.flag-de {
    background-image: url('#{$IMG_PATH}Germany.png');
    width: 16px;
    height: 16px;
}

.flag-en {
    background-image: url('#{$IMG_PATH}England.png');
    width: 16px;
    height: 16px;
}

@mixin font-DIN-next-medium($size: $FONTSIZE_BASE_SM_UP, $lineheight: $LINEHEIGHT_BASE) {
    font-family: 'DIN Next W02 Medium', sans-serif;
    font-size: $size;
    line-height: $lineheight;
}

@mixin font-DIN-next-light($size: $FONTSIZE_BASE_SM_UP, $lineheight: $LINEHEIGHT_BASE) {
    font-family: 'DIN Next W02 Light', sans-serif;
    font-size: $size;
    line-height: $lineheight;
    font-weight: lighter;
}

@mixin font-DIN-next-bold($size: $FONTSIZE_BASE_SM_UP, $lineheight: $LINEHEIGHT_BASE) {
    font-family: 'DIN Next W02 Bold', sans-serif;
    font-size: $size;
    line-height: $lineheight;
}

@mixin font-DIN-next-regular($size: $FONTSIZE_BASE_SM_UP, $lineheight: $LINEHEIGHT_BASE) {
    font-family: 'DIN Next W02 Regular', sans-serif;
    font-size: $size;
    line-height: $lineheight;
    font-weight: normal;
}

@mixin font-default($size: $FONTSIZE_BASE_SM_UP, $lineheight: $LINEHEIGHT_BASE) {
    @include font-DIN-next-regular($size, $lineheight);
}

@mixin primary-headline-config {
    @include font-DIN-next-medium(remcalc($PRIMARY_HEADLINE_SIZE), remcalc($PRIMARY_HEADLINE_HEIGHT));

}

@mixin secondary-headline-config($type: "default") {
    @if $type == "regular" {
        @include font-DIN-next-regular(remcalc($SECONDARY_HEADLINE_SIZE), remcalc($SECONDARY_HEADLINE_HEIGHT));
    } @else if $type == "light" {
        @include font-DIN-next-light(remcalc($SECONDARY_HEADLINE_SIZE), remcalc($SECONDARY_HEADLINE_HEIGHT));
    } @else if $type == "bold" {
        @include font-DIN-next-bold(remcalc($SECONDARY_HEADLINE_SIZE), remcalc($SECONDARY_HEADLINE_HEIGHT));
    } @else {
        @include font-DIN-next-medium(remcalc($SECONDARY_HEADLINE_SIZE), remcalc($SECONDARY_HEADLINE_HEIGHT));
    }

}

@mixin tertiary-headline-config($type: "default") {
    @if $type == "regular" {
        @include font-DIN-next-regular(remcalc($TERTIARY_HEADLINE_SIZE), remcalc($TERTIARY_HEADLINE_HEIGHT));
    } @else if $type == "light" {
        @include font-DIN-next-light(remcalc($TERTIARY_HEADLINE_SIZE), remcalc($TERTIARY_HEADLINE_HEIGHT));
    } @else if $type == "bold" {
        @include font-DIN-next-bold(remcalc($TERTIARY_HEADLINE_SIZE), remcalc($TERTIARY_HEADLINE_HEIGHT));
    } @else {
        @include font-DIN-next-medium(remcalc($TERTIARY_HEADLINE_SIZE), remcalc($TERTIARY_HEADLINE_HEIGHT));
    }
    //letter-spacing: $TERTIARY_HEADLINE_SPACING;

}

@mixin subheadline-config($type: "default") {
    @if $type == "regular" {
        @include font-DIN-next-regular($COPYTEXT_SIZE_MD_UP, $LINEHEIGHT-BASE);
    } @else if $type == "light" {
        @include font-DIN-next-light($COPYTEXT_SIZE_MD_UP, $LINEHEIGHT-BASE);
    } @else if $type == "bold" {
        @include font-DIN-next-bold($SECONDARY_HEADLINE_SIZE, $SECONDARY_HEADLINE_HEIGHT);
    } @else {
        @include font-DIN-next-medium($COPYTEXT_SIZE_MD_UP, $LINEHEIGHT-BASE);
    }
    @include breakpoint(sm-only) {
        font-size: $COPYTEXT_SIZE_SM;
    }

    @include breakpoint(xs-only) {
        font-size: $COPYTEXT_SIZE_XS;
    }
}

@mixin copytext-config($type: "default") {
    @if $type == "regular" {
        @include font-DIN-next-regular($COPYTEXT_SIZE_MD_UP, $LINEHEIGHT-BASE);
    } @else if $type == "medium" {
        @include font-DIN-next-medium($COPYTEXT_SIZE_MD_UP, $LINEHEIGHT-BASE);
    } @else if $type == "bold" {
        @include font-DIN-next-bold($SECONDARY_HEADLINE_SIZE, $SECONDARY_HEADLINE_HEIGHT);
    } @else {
        @include font-DIN-next-light($COPYTEXT_SIZE_MD_UP, $LINEHEIGHT-BASE);
    }
    @include breakpoint(sm-only) {
        font-size: $COPYTEXT_SIZE_SM;
    }

    @include breakpoint(xs-only) {
        font-size: $COPYTEXT_SIZE_XS;
    }
}

@mixin buttontext-config {
    @include font-DIN-next-regular($BUTTON_TEXT_SIZE_XS, $BUTTON_LINE_HEIGHT_XS);
    color: $BUTTON_TEXT_COLOR;
    text-decoration: none;
}
