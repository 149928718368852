//###[ GLOBAL SETTINGS NEEDED FOR EXTERNAL INCLUSION OF GLOBALS ]#######################################################

* {
    -webkit-font-smoothing: antialiased;
}


html {
    height: 100%;
    @include font-default($FONTSIZE_BASE_SM_UP);
    @include breakpoint(xs-only) {
        font-size: $FONTSIZE_BASE_XS;
    }


    body {
        width: 100%;
        overflow-x: hidden;
        @include font-default($FONTSIZE_BASE_SM_UP);
        @include breakpoint(xs-only) {
            font-size: $FONTSIZE_BASE_XS;
        }


        .ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
            z-index: 1000;
        }
    }
}


html.no-overflow {
    overflow: hidden;

    position: relative;

    height: 100vh !important;


    body {
        overflow: hidden;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}


img {
    max-width: 100%;
    height: auto;
}


//###[ HEADER ]#########################################################################################################

header.main-header-container {
    position: absolute !important;
    right: 0;
    left: 0;
    z-index: 999 !important;

    margin-bottom: 30px;
    background-color: rgba($BODY_BG_COLOR, 0.851);
    box-shadow: 5px 8.66px 30px 0 rgba(0, 0, 0, 0.2);

    transition: background-color 0.2s;

    backface-visibility: hidden;
    transform: translateZ(0);

    // has open flyout submenu on desktop
    &.open-flyout {
        background-color: $BODY_BG_COLOR;
    }


    // desktop meta header above main nav containing stuff like contact and retailer search
    .meta-header {
        display: none;

        @include breakpoint(md) {
            display: block;
        }


        background-color: $MAIN_LIGHT_COLOR;


        .meta-header-container {
            @include container();


            .meta-header-row {
                @include make-row();


                .meta-header-content {
                    @include make-xs-column(12);


                    ul {
                        padding: 0;

                        @include font-DIN-next-regular(remcalc(16px));
                        line-height: remcalc(38px);

                        list-style: none;


                        li {
                            float: left;

                            margin-right: 34px;

                            text-transform: uppercase;


                            a {
                                color: $MAIN_COLOR;
                                text-decoration: none !important;

                                &:hover {
                                    color: $SECONDARY_LIGHT_COLOR;
                                    text-decoration: none;
                                }
                            }


                            &.active {
                                a {
                                    color: $SECONDARY_LIGHT_COLOR;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    .language-nav {
                        float: right;
                        background-color: $MAIN_LIGHT_COLOR;
                        text-transform: uppercase;
                        @include font-DIN-next-regular(remcalc(16px));
                        line-height: remcalc(38px);
                        color: $MAIN_COLOR;
                        position: relative;
                        display: inline-block;
                        height: 100%;
                        min-width: 150px;
                        cursor: pointer;
                        padding-left: 20px;
                        padding-right: 20px;

                        &:after {
                            content: $icon-arrow_down;
                            @extend .dolle-icon;
                            position: absolute;
                            top: -2px;
                            right: 10px;
                            font-size: 7px;
                            line-height: remcalc(38px);
                            color: $SECONDARY_LIGHT_COLOR;
                            font-weight: bold;
                        }

                        .dropdown-content {
                            display: none;
                            position: absolute;
                            background-color: #FFFFFF;
                            padding: 0 20px;
                            z-index: 2;
                            left: 0;
                            width: 100%;

                            li {
                                display: block;
                                margin: 0;
                                width: 100%;
                                white-space: nowrap;
                                text-align: left;
                                line-height: 1.5;
                                padding-bottom: 5px;

                                a {
                                    display: inline-block;
                                    width: calc(100% - 25px);
                                    text-align: left;
                                    white-space: normal;
                                }

                                .flag {
                                    float: none;
                                    margin-top: 3px;
                                    margin-left: 0;
                                    vertical-align: top;
                                }

                                &.active {
                                    display: none;
                                }
                            }
                        }

                        &.visible {
                            background-color: #FFFFFF;

                            &:after {
                                content: $icon-arrow_up;
                                background-color: #FFFFFF;
                            }

                            .dropdown-content {
                                display: block;
                            }
                        }

                        .flag {
                            display: inline-block;
                            vertical-align: middle;
                            margin-top: 10px;
                            margin-right: 9px;
                            margin-left: 10px;
                            float: right;
                        }
                    }
                }
            }
        }
    }


    // the burger menu button
    .navbar-toggle {
        float: left;

        border: 0;
        margin: 0;
        margin-left: 5px;

        background: none !important;


        .icon-bar {
            background-color: $MAIN_COLOR;
            border-radius: 0;
            transition: all 0.2s;
        }


        .top-bar {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
        }


        .middle-bar {
            opacity: 0;
        }


        .bottom-bar {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
        }


        &.collapsed {
            .top-bar {
                transform: rotate(0deg);
            }


            .middle-bar {
                opacity: 1;
            }


            .bottom-bar {
                transform: rotate(0deg);
            }
        }
    }


    // the main site header
    .site-header {
        @include container();

        position: relative;

        padding-top: 10px;

        @include breakpoint(md) {
            position: static;

            padding-top: 15px;
            margin-top: 15px;
        }


        .site-header-row {
            @include make-row();


            // ci logo
            .logo-wrapper {
                @include make-md-column(3);


                img {
                    position: absolute;
                    top: 0;
                    z-index: 9999;

                    max-width: 184px;

                    @include breakpoint(md) {
                        max-width: 100%;
                    }
                }
            }

            .header-cta {
                float: right;
                position: absolute;
                bottom: 5px;
                right: 10px;
                z-index: 1;
                @include breakpoint(md) {
                    position: static;
                    margin-top: remcalc(10);
                }
            }

            // this wrapper is used in mobile with an active search, in that case the original search
            // button disappears an becomes part of the search form, but we want to have a close button
            // outside of the form in the row above, for that, this wrapper rebuilds close and cart
            // button in the top row
            .search-button-wrapper {
                float: right;

                position: relative;

                padding: 2px 35px 0 0;

                @include breakpoint(sm) {
                    display: none;
                }

                @include breakpoint(sm-only) {
                    display: block;
                }

                @include breakpoint(xs) {
                    display: block;
                }

                .icon-cart {
                    @include breakpoint(sm) {
                        display: block;
                    }

                    @include breakpoint(md) {
                        display: none;
                    }
                }

                .close-search {
                    position: absolute;
                    top: 8px;
                    right: 90px;

                    color: $MAIN_COLOR;
                    text-decoration: none;

                    @include breakpoint(sm) {
                        display: none;
                    }
                }
            }


            // this contains the nav elements as well as the default functional icons such as search and cart
            .mainnav-header {
                @include make-md-column(9);

                position: static;

                min-height: 0;

                padding-top: 14px;


                .navbar-collapse {
                    padding: 0;
                }


                .navbar {
                    float: left;

                    position: static;

                    border: 0;
                    margin-bottom: 0;

                    &:not(.navbar-collapse) {
                        float: right;
                    }

                    .nav > li {
                        position: static;

                        > a:not(.icon-search) {
                            cursor: default;

                            padding: remcalc(15px) 0 remcalc(22px) remcalc(22px);

                            text-transform: uppercase;

                            @include breakpoint(lg) {
                                padding: 15px 30px 22px 40px;
                            }

                            &:after {
                                @extend .dolle-icon;
                                content: $icon-arrow_down;

                                display: none;

                                position: absolute;
                                top: 0;
                                right: 0;

                                font-size: 8px;
                                line-height: 40px;
                                color: $SECONDARY_LIGHT_COLOR;
                            }

                            &[href] {
                                cursor: pointer;
                            }

                            &.selected {
                                color: $SECONDARY_LIGHT_COLOR;
                            }
                        }

                        &:not(.has-children), &.active:not(.has-children) {
                            > a:after {
                                content: '' !important;
                            }
                        }

                        &:first-child {
                            > a {
                                padding-left: 0 !important;
                            }
                        }

                        &:last-child {
                            > a {
                                padding-right: 0 !important;
                            }
                        }

                        > .flyout {
                            @include breakpoint(md) {
                                position: absolute;
                                min-width: 160px;
                                padding-left: 5px;
                                padding-right: 5px;
                                //margin: 2px 0 0;
                                list-style: none;
                                font-size: 14px;
                                text-align: left;
                                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                                background-clip: padding-box;
                                transition: opacity 0.3s ease-in-out;
                                display: block;
                                opacity: 0;
                                z-index: -1;
                                top: -9999px;
                                left: 0;
                                right: 0;
                                border: 0;

                            }

                            float: none;
                            padding-top: 40px;
                            background-color: $BODY_BG_COLOR;

                            &.open {
                                opacity: 1;
                                top: 100%;
                                z-index: 9;
                            }

                            .flyout-container {
                                @include container();

                                margin-bottom: 30px;


                                .flyout-nav {
                                    padding: 0;
                                    list-style: none;
                                    @include make-row();


                                    > li {
                                        @include make-md-column(3);

                                        > a {
                                            display: block;

                                            @include font-DIN-next-regular(remcalc(20px));
                                            text-transform: uppercase;
                                            line-height: remcalc(24px);

                                            border-bottom: 2px solid $MAIN_BORDER_COLOR;
                                            margin-bottom: 10px;
                                        }

                                        &.first {
                                            /* @include make-md-column(6); */
                                            .level-3 {
                                                @include breakpoint(md) {
                                                    columns: 1;
                                                    column-gap: $COLUMN_GAP;
                                                }
                                            }
                                        }
                                    }


                                    ul {
                                        padding: 0;
                                        list-style: none;
                                        @include font-DIN-next-regular(remcalc(18px));

                                        li {
                                            @extend .clearfix;

                                            margin-bottom: 10px;
                                            /*display: flex;
                                            align-items: center;*/
                                            display: inline-block;
                                            width: 100%;

                                            img {
                                                width: 95px;
                                                height: auto;
                                                padding: 1px;
                                                margin-right: 10px;
                                                border: 1px solid $MAIN_BORDER_COLOR;
                                            }

                                            a {
                                                color: $MAIN_COLOR;
                                                float: left;

                                                &:hover {
                                                    text-decoration: none;
                                                    color: $SECONDARY_LIGHT_COLOR;
                                                }
                                            }

                                            a + a {
                                                line-height: 1;
                                                width: calc(100% - 105px);
                                                min-height: 68px;
                                                display: flex;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }

                                .flyout-teaser,
                                .flyout-slider {
                                    > a {
                                        position: relative;
                                        display: block;
                                        text-align: center;
                                        clear: both;
                                        border-top: 1px solid $MAIN_BORDER_COLOR_DARK;
                                        @include font-DIN-next-regular(remcalc(18px));
                                        padding-top: 14px;

                                        &:before {
                                            @extend .dolle-icon;
                                            content: $icon-arrow_down;

                                            position: absolute;
                                            top: -1px;
                                            left: 0;

                                            margin-left: calc(50% - 6.6px);

                                            color: $MAIN_BORDER_COLOR_DARK;
                                            text-align: center;
                                            font-size: 8px;

                                            background-color: $BG_COLOR;
                                        }
                                    }
                                }

                                .flyout-slider {
                                    .teaser-slider-wrapper {
                                        @include make-sm-column(12);
                                        padding-bottom: 30px;
                                    }

                                    .module-subnavigation_teaser_type2 {
                                        margin-right: 30px;
                                    }
                                }

                                .flyout-slider,
                                .flyout-teaser {
                                    @include make-row();

                                    .module-subnavigation_teaser_type3 {
                                        @include make-sm-column(4);
                                    }

                                    .module-subnavigation_teaser_type2,
                                    .module-subnavigation_teaser_type3 {

                                        .subnav-teaser-img-wrapper {
                                            width: 33.33333%;
                                            padding-right: 15px;
                                            float: left;

                                            img {
                                                padding: 1px;
                                                border: 1px solid $MAIN_BORDER_COLOR;
                                                height: auto;
                                                width: auto;
                                            }
                                        }

                                        .subnav-teaser-text-wrapper {
                                            width: 66.66667%;
                                            padding-left: 15px;
                                            float: left;
                                        }

                                        .teaser-headline {
                                            & > a {
                                                @include font-DIN-next-regular(remcalc(20px));
                                                color: $MAIN_COLOR !important;
                                                text-transform: uppercase;
                                                text-decoration: none !important;
                                            }
                                        }

                                        .teaser-headline ~ p {
                                            @include font-DIN-next-light(1rem);
                                            color: $MAIN_COLOR;
                                        }

                                        .more-link {
                                            @include font-DIN-next-regular(remcalc(18px));
                                            margin-bottom: 20px;
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .language-nav {
                    margin-left: 230px;
                    margin-right: 230px;
                    border-top: 1px solid $FOOTER_BORDER_COLOR;
                    text-transform: uppercase;
                    color: $MAIN_COLOR;
                    line-height: 40px;
                    position: relative;

                    @include breakpoint(xs-only) {
                        margin: 0 15px;
                    }

                    &:after {
                        content: $icon-arrow_down;
                        @extend .dolle-icon;
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: 8px;
                        line-height: 40px;
                        color: $SECONDARY_LIGHT_COLOR;
                    }

                    .flag {
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: 10px;
                        margin-right: 20px;
                        float: right;
                    }

                    .dropdown-content {
                        display: none;
                        background-color: #eeeeed;
                        padding-left: 15px;
                        padding-right: 15px;

                        li {
                            display: block;
                            margin: 0;
                            width: 100%;

                            &:not(.active) {
                                border-bottom: 1px solid $FOOTER_BORDER_COLOR;
                            }

                            &:last-child {
                                border: none;
                            }

                            &.active {
                                display: none;
                            }

                            .flag {
                                margin-right: 5px;
                            }
                        }
                    }

                    &.visible {
                        .dropdown-content {
                            display: block;
                            margin-bottom: 15px;
                        }

                        &:after {
                            content: $icon-arrow_up;
                        }
                    }
                }

                .navbar-cart-search-wrapper {
                    margin-left: 40px;


                    .navbar-cart-search > li {
                        .minicart-wrapper > a,
                        > a {
                            line-height: 27px;
                            float: left;
                            cursor: pointer;
                            display: inline-block;
                            padding: 12px 20px 14px 20px;
                            @include breakpoint(lg) {
                                padding: 12px 0 14px 20px;
                            }

                            &.icon-search {
                                padding-bottom: 19px !important;
                            }

                            &.icon-search, &.close-search {
                                cursor: pointer;
                            }

                            &.close-search {
                                display: none;
                                float: left;
                            }
                        }

                        .form-search {
                            display: none;
                            float: left;
                            margin-top: 10px;
                            padding-bottom: 8px;

                            &.active {
                                position: absolute;
                            }

                            input {
                                width: 100%;
                                height: $BUTTON_LINE_HEIGHT_SM_UP;
                            }
                        }
                    }
                }


                .search-active {
                    #navbar {
                        display: none !important;
                    }

                    .navbar-cart-search-wrapper {
                        width: 100%;
                        z-index: 2;

                        ul {
                            width: 100%;
                        }

                        li {
                            float: none;

                            .form-search {
                                display: block;
                                width: 80%;

                                @include breakpoint(xs-only) {
                                    width: calc(100% - 40px);
                                }

                                #tx-solr-search {
                                    width: 100%;
                                    float: none;

                                    .tx-solr-search-form {
                                        background: none;
                                        border: none;
                                        padding: 0;
                                        margin: 0;


                                        .tx-solr-q {
                                            line-height: 40px;
                                        }
                                    }
                                }
                            }

                            a.close-search, a.icon-search {
                                margin-top: 10px;
                                line-height: 37px;
                            }

                            a.close-search {
                                display: none;
                                padding: 0 20px 0 20px !important;

                                @include breakpoint(sm) {
                                    display: inline-block;
                                }
                            }

                            .minicart-wrapper {
                                display: none;
                            }

                            a.icon-search {
                                padding: 0 20px 0 20px !important;
                                font-weight: bold;
                                line-height: 40px;

                                @include breakpoint(xs-only) {
                                    float: right;
                                    background: $SECONDARY_LIGHT_COLOR;
                                    color: $BUTTON_TEXT_COLOR;
                                    height: $BUTTON_LINE_HEIGHT_SM_UP;
                                    width: $BUTTON_LINE_HEIGHT_SM_UP;
                                    padding: 0 !important;
                                    text-align: center;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }


            // definitions for mobile main menu
            @media (max-width: $screen-sm-max) {
                .logo-wrapper {
                    z-index: 3;
                    float: left;


                    a {
                        display: block;

                        margin-top: 6px;
                        margin-bottom: -10px;
                    }


                    img {
                        position: static;

                        width: auto;
                        height: 40px;
                    }
                }


                .mainnav-header {
                    //display: none;
                    clear: both;

                    position: static;

                    padding: 0;

                    box-shadow: inset 0 28.66px 45px -35px rgba(0, 0, 0, 0.2);

                    .navbar {
                        min-height: 0;
                    }


                    .navbar-cart-search-wrapper,
                    #navbar {
                        overflow-x: hidden;
                        float: none;


                        .minicart-wrapper {
                            margin-top: 0;
                        }


                        .nav > li,
                        .meta-nav > li {
                            @include gutterbased(padding-right, 0.5);
                            @include gutterbased(padding-left, 0.5);

                            > a {
                                display: block;

                                position: relative;

                                padding: 0 !important;
                                border-top: 1px solid $FOOTER_BORDER_COLOR;
                                border-bottom: 0;
                                margin: 0;

                                text-transform: uppercase;
                                line-height: 40px;

                                &:after {
                                    display: block;
                                }

                                &.toggled {
                                    &:after {
                                        content: $icon-arrow_up;
                                    }
                                }

                                &:focus {
                                    color: $MAIN_COLOR;
                                }

                            }


                            %open-flyout {
                                display: block;

                                position: static;

                                background-color: #eeeeed;


                                .flyout-nav.flyout-type-1 {
                                    > li {
                                        > a {
                                            height: 40px;

                                            border-top: 1px solid $FOOTER_BORDER_COLOR;
                                            border-bottom: 1px solid $FOOTER_BORDER_COLOR;
                                            margin-bottom: 0;

                                            line-height: 39px;
                                        }


                                        &:first-of-type {
                                            > a {
                                                border-top: 0;
                                            }
                                        }


                                        &:last-of-type {
                                            > a:last-of-type {
                                                border-bottom: 0;
                                            }
                                        }
                                    }


                                    .menu.level-3 {
                                        img {
                                            display: none;
                                        }


                                        li {
                                            display: block;

                                            height: 40px;

                                            padding-left: 23px;
                                            margin-bottom: 0;

                                            line-height: 40px;


                                            a {
                                                min-height: 0;

                                                @include font-DIN-next-light(1rem, 39px);
                                                color: $MAIN_COLOR;

                                                &:last-child {
                                                    display: block;

                                                    width: 100%;

                                                    border-bottom: 1px solid $FOOTER_BORDER_COLOR;
                                                }
                                            }


                                            &.active {
                                                background-color: $SECONDARY_COLOR;

                                                a {
                                                    border-bottom-color: transparent;

                                                    color: #fff;
                                                }
                                            }


                                            &:last-of-type {
                                                a {
                                                    border-bottom: 0;
                                                }
                                            }
                                        }
                                    }
                                }


                                .flyout-type-1,
                                .flyout-type-2,
                                .flyout-type-3 {
                                    margin: 0;
                                }

                                .flyout-type-1 {
                                    padding: 5px 0;
                                }

                                .flyout-type-2,
                                .flyout-type-3 {
                                    padding: 20px 0;
                                }
                            }


                            &.active {
                                > a {
                                    &:after {
                                        content: $icon-arrow_up;
                                    }
                                }


                                .flyout {
                                    @extend %open-flyout;
                                }
                            }


                            .flyout {
                                display: none;

                                padding-top: 0;
                                margin: 0;

                                box-shadow: none;


                                .flyout-container {
                                    width: auto;

                                    padding: 0;


                                    .flyout-nav,
                                    .flyout-teaser,
                                    .flyout-slider {
                                        > li > a,
                                        > li > ul > li > a,
                                        > li > p,
                                        > li > ul > li > p {
                                            font-size: 1rem;
                                        }
                                    }


                                    .flyout-slider {
                                        > a {
                                            &:before {
                                                background-color: #eeeeed;
                                            }
                                        }
                                    }


                                    .flyout-teaser {
                                        .module-subnavigation_teaser_type3 {
                                            &:last-child {
                                                :last-child {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.show {
                                    @extend %open-flyout;
                                }
                            }
                        }


                        > .nav > li.first > a {
                            border-top: 0;
                        }
                    }


                    .navbar-nav {
                        margin: 0;
                    }


                    .navbar:not(.navbar-collapse) {
                        position: absolute;
                        right: 15px;
                        top: 0;
                    }


                    .slick-track {
                        min-width: 260px !important;
                    }


                    .navbar-cart-search-wrapper {
                        .nav > li {
                            float: left;

                            padding: 0;


                            a.icon-search {
                                border: 0;
                                padding-top: 4px !important;
                                padding-bottom: 0 !important;
                            }
                        }
                    }


                    .search-active {
                        .navbar-cart-search-wrapper {
                            position: absolute;
                            top: 50px;
                            right: 0;
                            left: 0;

                            width: auto;

                            margin: 0;


                            .navbar-cart-search {
                                padding: 15px 15px 15px;

                                background-color: #fff;

                                box-shadow: inset 0 28.66px 45px -35px rgba(0, 0, 0, 0.2);


                                li {
                                    width: 100%;


                                    a.icon-search {
                                        padding-top: 0 !important;
                                    }


                                    a.close-search {
                                        border-top: 0;
                                    }
                                }


                                .flyout {
                                    overflow: hidden;

                                    margin-top: 60px;
                                }
                            }
                        }
                    }
                }
            }


            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                .navbar-toggle {
                    margin-left: 18px;
                }


                .mainnav-header {
                    //@include gutterbased(margin-left, -0.5);
                    margin-left: -200px;
                    //@include gutterbased(margin-right, -0.5);
                    margin-right: -200px;


                    #navbar {
                        .nav > li,
                        .meta-nav > li {
                            //@include gutterbased(padding-right, 1.0);
                            padding-right: 200px + gutterval(0.5);
                            //@include gutterbased(padding-left, 1.0);
                            padding-left: 200px + gutterval(0.5);
                        }
                    }
                }
            }
        }
    }


    // mobile burger menu is open
    @media (max-width: $screen-sm-max) {
        .site-header {
            .site-header-row {
                .mainnav-header {
                    padding-top: 0;

                    transition: padding-top 400ms;
                }
            }
        }


        &.nav-is-collapse {
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            position: absolute !important;
            top: 0;
            right: 0;
            //bottom: 0;
            left: 0;

            width: 100%;
            height: 100vh;

            margin-bottom: 0;

            background-color: $BG_COLOR;


            .site-header {
                .site-header-row {
                    .mainnav-header {
                        display: block;

                        padding-top: 25px;
                    }
                }
            }
        }
    }


    #mainnav-dolle {
        .navbar-nav {
            > li {
                &.active {
                    > a {
                        color: $SECONDARY_LIGHT_COLOR;
                    }
                }
            }

            .flyout.active {
                display: none;
            }
        }

        .meta-nav {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                > a {
                    color: $MAIN_COLOR;
                    padding: 0 !important;
                    position: relative;
                    text-transform: uppercase;
                    display: block;
                    border-top: 1px solid $FOOTER_BORDER_COLOR;
                    border-bottom: 0;
                    line-height: 40px;
                    margin: 0;
                }

                &:last-child {
                    > a {
                        border-bottom: 1px solid $FOOTER_BORDER_COLOR;
                    }
                }

                &.active {
                    > a {
                        color: $SECONDARY_LIGHT_COLOR;

                        &:after {
                            display: none !important;
                        }
                    }
                }
            }
        }

        &.search-active {
            .flyout.active {
                display: block;
                opacity: 1;
                top: 100%;
                z-index: 9;

                .flyout-container-search {
                    position: relative;
                }
            }

            .loading-search {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 10;
                height: 100%;
                width: 100%;
                background-color: rgba(255, 255, 255, 0.8);

                img {
                    margin: 0 auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: calc(50% - 15px);
                }
            }
        }
    }
}

#sogem-eu {
    .logo-wrapper {
        > a {
            > img {
                max-height: 85px;
                width: auto;
            }
        }
    }
}

#dolle-shelving {
    header.main-header-container {
        .site-header {
            .site-header-row {
                .mainnav-header {
                    .navbar {
                        .nav > li {
                            & > .flyout {
                                .flyout-container {
                                    ul.flyout-nav {
                                        &.flyout-type-1 {
                                            & > li {
                                                &:not(.has-children) {
                                                    display: flex;
                                                    flex-direction: column-reverse;

                                                    img {
                                                        width: auto;
                                                        height: auto;
                                                    }

                                                    .img-link {
                                                        display: none;
                                                    }

                                                    @include breakpoint(md) {
                                                        .img-link {
                                                            display: block;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.mainnav-header .sub-navigation {
    @media (min-width: $screen-md) {
        box-shadow: 5px 8.66px 30px 0 rgba(0, 0, 0, 0.2);
        position: absolute !important;
        left: 0;
        right: 0;
        top: 135px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 10px;

        li {

            font-size: 16px;
            float: none;

            a {
                padding: 10px 10px 10px 10px;
            }


        }
        .flyout-nav > li {
            a {
                min-height: 100px;
            }

            .level-3 a {
                min-height: 1px;
            }
        }

        background-color: $MAIN_LIGHT_COLOR;
    }
}