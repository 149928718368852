form {

  .form-group {
    margin-bottom: 22px;

    .error {
      margin-top: 22px;
    }

    input.form-control {
      float: none;
    }
  }

  label {
    margin-bottom: 8px;

    > .required {
      color: $ERROR_COLOR;
    }
  }

  input::placeholder {
    @include font-DIN-next-light(remcalc(16px), 40px);
    // needs important because magento overwrites this specifically in styles-m :(
    color: $MAIN_COLOR !important;
    opacity: 1 !important;
  }

  .radio + .radio, .checkbox + .checkbox {
    margin-top: 10px;
  }

  .has-error {
    border-color: $ERROR_COLOR;
  }

  .error-wrapper {
    .error:last-child {
      margin-bottom: 22px;
    }
  }

  textarea.form-control {
    float: none;
  }
}


.btn {
  border-radius: 0;

  text-transform: uppercase;

  &.btn-forward {
    &:after {
      @extend .dolle-icon;
      content: $icon-arrow_right;

      margin-left: 8px;
      font-size: 11px;
    }
  }

  &.btn-clear {
    &:after {
      @extend .dolle-icon;
      content: $icon-x_icon;

      margin-left: 8px;
      font-size: 11px;
    }
  }
}


.form-control,
input {
  display: block;
  float: left;

  height: $BUTTON_LINE_HEIGHT_SM_UP;

  padding: 0 10px;
  border: 1px solid $INPUT_BORDER_COLOR;
  border-radius: 0;
  outline: none;
  margin: 0 4px 0 0;

  @include font-DIN-next-regular(remcalc(16px));
  color: $INPUT_TEXT_COLOR;
  line-height: $BUTTON_LINE_HEIGHT_SM_UP;

  background-color: $INPUT_BG_COLOR;

  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.1);

  @include input-placeholder($INPUT_TEXT_COLOR);
}

.form-control {
  border-color: rgb(238, 232, 228);
}

input.form-control {
  line-height: 38px;
}


.form-group {
  label {
    @include font-DIN-next-light(remcalc(18px));
  }
}


.stylable-input:not(.selectproxy) {
  @include clearfix();

  position: relative;

  cursor: pointer;

  padding: 0;

  line-height: 40px;

  &:before {
    @extend .dolle-icon;
    content: '';

    float: left;

    width: 40px;
    height: 40px;

    border-width: 1px;
    border-style: solid;
    border-color: rgb(238, 232, 228);
    margin-right: 20px;

    text-align: center;
    line-height: 38px;

    background-color: rgb(255, 255, 255);

    box-shadow: inset 0 0 0 5px #FFF;
  }

  &.checked {
    @include font-DIN-next-bold(remcalc(18px), 38px);
    //does not work in ie11
    //because of that bug the check-icon wasn't shown
    //&:before {
    //	content: $icon-check;
    //	color: $CHECKMARK_GREEN;
    //}
  }

  &.has-error {
    &:before {
      border-color: $SECONDARY_LIGHT_COLOR;
    }
  }
}


input[type="radio"] + .stylable-input:not(.selectproxy),
.radio-wrapper + .stylable-input:not(.selectproxy) {
  &:before {
    border-radius: 100%;
  }

  &.checked {
    &:before {
      content: '';

      height: 40px;
      width: 40px;

      background-color: $BUTTON_BG_COLOR;
      box-shadow: inset 0 0 0 7px #FFF;
    }
  }
}


input[type="checkbox"] + .stylable-input:not(.selectproxy),
.checkbox.stylable-input:not(.selectproxy) {
  &.checked {
    &:after {
      content: '';
      position: absolute;

      top: 5px;
      left: 13px;

      height: 24px;
      width: 14px;

      border: solid $CHECKMARK_GREEN;
      border-width: 0 4px 4px 0;

      transform: rotate(46deg);
    }
  }
}


select {
  cursor: pointer;
}


.selectproxy {
  width: 100%;
  height: 40px;

  padding: 0;
  border: 1px solid rgb(238, 232, 228);

  line-height: 40px;


  .selectlabel {
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 0 48px 0 10px;

    @include font-DIN-next-regular(remcalc(16px), 38px);
    color: $MAIN_COLOR;
    white-space: nowrap;

    background-color: $BG_COLOR;

    &:after {
      content: $icon-arrow_down;
      @extend .dolle-icon;
      position: absolute;
      top: 0;
      right: 0;
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      font-size: 10px;
      color: #fff;
      background-color: $SECONDARY_LIGHT_COLOR;
    }
  }

  &.disabled-select {
    .selectlabel {
      opacity: 0.6;
    }

    select {
      cursor: not-allowed;
    }
  }
}


.Tx-Formhandler {
  @include clearfix();

  .success-message {
    margin: remcalc(100px) 0 remcalc(100px) 0;

    p {
      text-align: center;
    }
  }

  .stylable-input:not(.selectproxy) {
    &.checked {
      &:after {
        content: '';
        position: absolute;

        top: 5px;
        left: 13px;

        height: 24px;
        width: 14px;

        border: solid $CHECKMARK_GREEN;
        border-width: 0 4px 4px 0;

        transform: rotate(46deg);
      }
    }
  }
}
